<template>
  <div class="category-main">
    <v-container>
      <Breadcrumb :items="breadcrumb" />
    </v-container>
    <v-container v-if="headerProposals.length > 0">
      <ProposalSlider
        :title="certificatesTitle"
        :proposals="certificatesProposals"
      />
      <component
        :is="headerMode"
        :title="headerTitle"
        :proposals="headerProposals"
        paginationClass="pagination-header"
        :cols="1"
        :sm="2"
        :md="2"
        :lg="2"
      />
    </v-container>
    <v-container>
      <CategoryTitle :category="category" />
    </v-container>
    <v-container>
      <CategoryMainSlider
        :categories="category.children"
        :key="category.categoryId"
        paginationClass="subcategory"
      />
    </v-container>
    <v-container>
      <component
        v-if="middleProposals.length > 0"
        :is="middleMode"
        :title="middleTitle"
        :proposals="middleProposals"
        paginationClass="pagination-middle"
        :cols="1"
        :sm="2"
        :md="2"
        :lg="2"
      />
      <ProductListSlider
        v-else-if="!category.metaData.category_info.HIDE_CRM"
        :layout="25"
        :categoryId="category.categoryId"
        :promo="true"
        :title="'In promozione in ' + category.name"
        :paginationClass="'swiper-pagination-promo'"
      >
        <h3>
          In promozione in <strong>{{ category.name }}</strong>
        </h3>
      </ProductListSlider>
    </v-container>
    <v-container>
      <component
        v-if="footerProposals.length > 0"
        :is="footerMode"
        :title="footerTitle"
        :proposals="footerProposals"
        paginationClass="pagination-footer"
        :cols="1"
        :sm="2"
        :md="2"
        :lg="2"
      />
      <ProductListSlider
        v-else-if="!category.metaData.category_info.HIDE_CRM"
        :layout="27"
        :categoryId="category.categoryId"
        :paginationClass="'swiper-pagination-venduti'"
      >
        <h3>
          I più venduti in <strong>{{ category.name }}</strong>
        </h3>
      </ProductListSlider>
    </v-container>
  </div>
</template>
<style scoped lang="scss">
.center-img {
  margin-right: auto;
  margin-left: auto;
}
</style>
<script>
// @ is an alias to /src
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ProductListSlider from "@/components/product/ProductListSlider.vue";
import CategoryMainSlider from "@/components/category/CategoryMainSlider.vue";
import categoryMixins from "~/mixins/category";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";

import get from "lodash/get";
import { mapProposalComponent } from "~/service/ebsn";

export default {
  name: "CategoryMain",
  mixins: [categoryMixins],
  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 20,
        watchOverflow: true,
        pagination: {
          el: ".firstCategory",

          clickable: true
        }
      },
      count: -1
    };
  },
  components: {
    CategoryTitle,
    CategoryMainSlider,
    Breadcrumb,
    ProductListSlider,
    ProposalProductListSlider: () =>
      import("@/components/proposal/ProposalProductSlider.vue"),
    ProposalProductGrid: () =>
      import("@/components/proposal/ProposalProductGrid.vue"),
    ProposalSlider: () => import("@/components/proposal/ProposalSlider.vue"),
    ProposalGrid: () => import("@/components/proposal/ProposalProductGrid.vue")
  },
  computed: {
    ...mapProposalComponent({
      header: "header",
      middle: "middle",
      footer: "footer",
      certificates: "Certificate Banner"
    })
  },
  methods: {
    updateCount(count) {
      this.count = count;
    }
  },
  metaInfo() {
    return {
      title: get(
        this.category,
        "metaData.category_seo.SEO_TITLE",
        this.category ? this.category.name : ""
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(
            this.category,
            "metaData.category_seo.SEO_DESCRIPTION",
            this.$t("meta.category.firstLevel.description", [
              this.category.name
            ])
          )
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: get(this.category, "metaData.category_seo.SEO_KEYWORDS")
        },
        // Open Graph Metas
        {
          property: "og:locale",
          content: "it_IT"
        },
        {
          property: "og:title",
          content: get(
            this.category,
            "metaData.category_seo.SEO_TITLE",
            this.category ? this.category.name : ""
          )
        },
        {
          property: "og:type",
          content: "product.group"
        },
        {
          property: "og:url",
          content: location.href
        },
        {
          property: "og:image",
          content: "https://" + window.location.host + "/logo/social-logo.png"
        },
        {
          property: "og:image:alt",
          content: get(
            this.category,
            "metaData.category_seo.SEO_DESCRIPTION",
            this.$t("meta.category.firstLevel.description", [
              this.category.name
            ])
          )
        },
        {
          property: "og:site_name",
          content: "DupliClick"
        },
        // Twitter Metas
        {
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          name: "twitter:title",
          content: get(
            this.category,
            "metaData.category_seo.SEO_TITLE",
            this.category ? this.category.name : ""
          )
        },
        {
          name: "twitter:image",
          content: "https://" + window.location.host + "/logo/social-logo.png"
        },
        {
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  }
};
</script>
